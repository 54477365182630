import React from 'react';
import { useState, useEffect } from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import screen from '../assets/images/screen.png';
import pic1 from '../assets/images/pic01.jpg';
import pic2 from '../assets/images/pic02.jpg';
import pic3 from '../assets/images/pic03.jpg';

import section1Img from '../assets/images/1-min.png'
import section2Img from '../assets/images/2-min.png'
import section3Img from '../assets/images/3-min.png'
import section4Img from '../assets/images/4-min.png'
import bannerImg from '../assets/images/Banner.jpg'

import ChillNowImg from '../assets/images/ChillNow.png'
import SensImg from '../assets/images/Sens.png'
import WashowImg from '../assets/images/Washow.png'


import { Link } from 'gatsby';
import YouTube from 'react-youtube';

const IndexPage = () => {
  const windowSize = useWindowSize();
  let ytSize = null
  if (windowSize.width) {
    const MIN_YT_LEN = 200
    let w = (windowSize.width) * 0.75 > MIN_YT_LEN ? (windowSize.width) * 0.75 : MIN_YT_LEN
    ytSize = {
      w, h: w * (9/16) 
    }
  }

  return (
  <Layout>
    <header id="header">
      <div className="content">
        <h1>
          <a href="/#">{config.headingEN}</a>
        </h1>
        <p>{config.subHeadingEN}</p>
      </div>
      <div className="image phone">
        <div className="inner">
          <img src={bannerImg} alt="" />
        </div>
      </div>
    </header>

    <section id="one" className="wrapper style2 special">
      <header className="major">
        <h2>Demostration</h2>
      </header>
      <ul className="icons major">
        <p>Type 1: CW version - Supporting BlockChain signing function</p>
        <li>
          {ytSize && <YouTube videoId="L4JRQt7jmdQ" opts={{width:ytSize.w, height:ytSize.h}} />}
        </li>
      </ul>
    </section>

    <section id="two" className="wrapper">
      <div className="inner alt">
        <section className="spotlight">
          <div className="image">
            <img src={section1Img} alt="" />
          </div>
          <div className="content">
            <h3>Interaction with smartphone with NFC</h3>
            <p>
              Without APP installing, reducing the lead time before interaction. Both of Android and iOS supported, enable widest application scenario.
            </p>
          </div>
        </section>
        <section className="spotlight">
          <div className="image">
            <img src={section2Img} alt="" />
          </div>
          <div className="content">
            <h3>Offline OTP NDEF mechanism</h3>
            <p>
              Anti-counterfeiting bt different valued returned per scanning. Hardware also support tamper-detection for protecting physical form.
            </p>
          </div>
        </section>
        <section className="spotlight">
          <div className="image">
            <img src={section3Img} alt="" />
          </div>
          <div className="content">
            <h3>Unlimited possibilities by APP / Web integration</h3>
            <p>
              Our team has rich experience in software and hardware integration development,
              and we can assist in your decicated APP and cloud systems.
            </p>
          </div>
        </section>
        <section className="spotlight">
          <div className="image">
            <img src={section4Img} alt="" />
          </div>
          <div className="content">
            <h3>Multiple physical packaging forms</h3>
            <p>
              From tag-type to card-type, you could select the best form for your product.
              We could also support printing appearance printing by you requirements.
            </p>
          </div>
        </section>
      </div>
    </section>

    <section id="one" className="wrapper style2 special">
      <header className="major">
        <h2>Partners</h2>
      </header>
      <ul className="icons major">
        <li>
          <img
            src={ChillNowImg}
            style={{ width: 100, height: 100 }}
          />
        </li>
        <li>
          <img
            src={SensImg}
            style={{ width: 100, height: 100 }}
          />
        </li>
        <li>
          <img
            src={WashowImg}
            style={{ width: 100, height: 100 }}
          />
        </li>
      </ul>
    </section>

    <section id="three" className="wrapper style2 special">
      <header className="major">
        <h2>Contact Us</h2>
        <p>Welcome to contact us for more information</p>
      </header>
      <ul className="actions special">
        <li>
          <a
            href="mailto:contact@revteltech.com"
            className="button primary icon fa-plus"
          >
            Get to know now !
          </a>
        </li>
      </ul>
    </section>

    <Footer />
  </Layout>
)
  }

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []); 

  return windowSize;
}

export default IndexPage;
